<template>
  <div class="chats">
    <infinite-loading
      direction="top"
      @infinite="infiniteHandler"
    >
      <div slot="no-more">
        Больше нет сообщений
      </div>
      <div slot="no-results">
        <span v-if="!messages.length">Отправьте первое сообщение</span>
      </div>
    </infinite-loading>

    <chat-log-messages
      ref="chatMessages"
      :messages="messages"
      :sender-id="sender.id"
    />
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { mapActions } from 'vuex'
import ChatLogMessages from './ChatLogMessages.vue'

export default {
  components: {
    ChatLogMessages,
    InfiniteLoading,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
    sender: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    messages: [],
    limit: 16,
    initiallyLoaded: false,
  }),
  computed: {
    offset() {
      let offset = 0
      this.messages.forEach(sender => {
        offset += sender.messages.length
      })
      return offset
    },
    lastGroup() {
      return this.messages[this.messages.length - 1]
    },
  },
  beforeDestroy() {
    this.unsubscribeFromChatMessages()
  },
  mounted() {
    this.listenForChatMessages({ chatId: this.chat.id, callback: this.processNewMessage })
  },
  methods: {
    ...mapActions({
      listenForChatMessages: 'websockets/listenForChatMessages',
      unsubscribeFromChatMessages: 'websockets/unsubscribeFromChatMessages',
    }),
    async processNewMessage({ messageId, senderId }) {
      if (senderId !== this.sender.id) {
        const message = await this.getMessage(messageId)
        this.addMessage(message)
      }
    },
    addMessage(message) {
      if (this.lastGroup && message.sender.id === this.lastGroup.sender.id) {
        this.lastGroup.messages.push(message)
      } else {
        this.messages = [...this.messages, ...this.formatMessages([message])]
      }
      this.chat.lastMessage = { ...message }

      this.scrollToBottom()
    },
    updateMessage(id, message) {
      const { messages } = this

      this.messages = messages.map(msgGroup => {
        const groupMessages = msgGroup.messages.map(msg => {
          let messageToUpdate = { ...msg }
          if (id === messageToUpdate.id) messageToUpdate = { ...message }
          return messageToUpdate
        })
        return { ...msgGroup, messages: groupMessages }
      })

      this.chat.lastMessage = { ...message }
      this.scrollToBottom()
    },
    async scrollToBottom() {
      await setTimeout(() => {
        this.$emit('scrollToBottom')
      })
    },
    infiniteHandler($state) {
      this.$chatsHttp.get(`/chats/${this.chat.id}/messages`, {
        params: {
          limit: this.limit,
          offset: this.offset,
        },
      }).then(async messages => {
        if (messages.length) {
          this.messages = [...this.formatMessages(messages), ...this.messages]
          if (messages.length < this.limit) $state.complete()
          else $state.loaded()
        } else {
          $state.complete()
        }
        if (!this.initiallyLoaded) {
          await this.scrollToBottom()
          this.initiallyLoaded = true
        }
      })
    },
    async getMessage(messageId) {
      const message = await this.$chatsHttp.get(`/chats/${this.chat.id}/messages/${messageId}`)
      return message
    },
    formatMessages(messages) {
      const chatLog = messages.reverse()

      const formattedChatLog = []
      let chatMessageSender = chatLog[0] ? chatLog[0].sender : undefined

      let msgGroup = {
        sender: chatMessageSender,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSender && chatMessageSender.id === msg.sender.id) {
          msgGroup.messages.push({
            ...msg,
          })
        } else {
          chatMessageSender = msg.sender
          formattedChatLog.push(msgGroup)
          msgGroup = {
            sender: chatMessageSender,
            messages: [{
              ...msg,
            }],
          }
        }
        if (index === chatLog.length - 1) {
          formattedChatLog.push(msgGroup)
        }
      })

      return formattedChatLog
    },
  },
}
</script>

<style scoped lang="scss">

</style>
