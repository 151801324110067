<template>
  <div>
    <div
      v-for="(msgGrp, index) in messages"
      :key="`group-${groupSender(msgGrp).id+String(index)}`"
      class="chat"
      :class="{'chat-left': groupSender(msgGrp).id !== senderId}"
    >
      <div class="chat-avatar sticky-top">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          :src="groupSender(msgGrp).avatar"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="(msgData, msgInd) in msgGrp.messages"
          :key="msgData.id+msgInd"
        >
          <div
            v-if="msgInd === 0"
            class="chat-sender"
          >
            <b-badge variant="light-primary">
              {{ msgData.sender ? msgData.sender.full_name : 'Нет данных' }}
            </b-badge>
          </div>
          <div class="chat-content p-0 ">
            <div v-if="msgData.attachment">
              <a
                v-if="['.jpg','.png','.gif'].includes(msgData.attachment.type)"
                :href="msgData.attachment.url"
                target="_blank"
              >
                <b-img
                  :src="msgData.attachment.url"
                  style="max-width: 300px"
                  class="w-100 cursor-pointer rounded-top"
                />
              </a>
              <b-img
                v-else-if="msgData.attachment.type === '.webp'"
                :src="msgData.attachment.url"
                style="max-width: 150px"
                class="w-100 p-2"
              />
            </div>
            <div class="py-50 px-1">
              <div
                v-if="msgData.type !== 'room'"
                class="w-100 d-flex align-items-start"
              >
                <p>{{ msgData.text }}</p>
                <div v-if="!msgData.created">
                  <b-spinner
                    class="ml-50"
                    small
                  />
                </div>
              </div>
              <div v-else>
                <p v-if="groupSender(msgGrp).id !== senderId">
                  Вы были приглашены в видеозвонок
                </p>
                <p v-else>
                  Вы создали комнату
                </p>
                <b-button
                  class="mt-50 mb-50"
                  block
                  :to="{name: 'cabinet.room', params: { room_id: msgData.text } }"
                  :variant="groupSender(msgGrp).id === senderId ? 'dark' : 'primary'"
                >
                  <feather-icon
                    icon="VideoIcon"
                    class="mr-50"
                  />
                  Перейти
                </b-button>
              </div>
              <div
                v-if="msgInd === msgGrp.messages.length - 1"
                class="d-flex"
                :class="{'justify-content-end': groupSender(msgGrp).id !== senderId}"
              >
                <p class="msg-created font-small-2 text-muted">
                  {{ msgData.created | timeIfToday }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar, BSpinner, BImg, BBadge, BButton,
} from 'bootstrap-vue'

export default {
  name: 'ChatLogMessages',
  components: {
    BAvatar, BSpinner, BImg, BBadge, BButton,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    senderId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    groupSender(group) {
      return group.sender ?? {}
    },
  },
}
</script>

<style scoped lang="scss">
.chat-content {
  //min-width: 100px;

  > div {
    justify-content: flex-end;
    p {
      word-break: break-word;
    }
  }
  .msg-created {
    margin-top: 0.5rem!important;
    opacity: 0.7;
  }
}

.chat-sender {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
}

.chat-left {
  .chat-content {
    > div {
      justify-content: flex-start;
    }
    .msg-created {
      text-align: end;
      margin-top: 0.5rem!important;
    }
  }
  .chat-sender {
    justify-content: flex-start;
  }
}
</style>
