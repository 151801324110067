<template>
  <div
    :class="{'content-right': !centered, 'mx-auto': centered}"
    :style="centered ? 'max-width: 700px' : ''"
  >
    <div class="content-wrapper">
      <div class="content-body">
        <div
          style="height: inherit"
        >
          <div
            class="body-content-overlay"
            :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
            @click="mqShallShowLeftSidebar = shallShowActiveChatContactSidebar = shallShowUserProfileSidebar = false"
          />

          <!-- Main Area -->
          <section class="chat-app-window">

            <!-- Start Chat Logo -->
            <div
              v-if="!selectedChat"
              class="start-chat-area"
            >
              <div class="mb-1 start-chat-icon">
                <feather-icon
                  icon="MessageSquareIcon"
                  size="56"
                />
              </div>
              <h4
                class="d-flex d-md-none sidebar-toggle start-chat-text"
                @click="$emit('onShowSidebar')"
              >
                Начать диалог
              </h4>
            </div>

            <!-- Chat Content -->
            <div
              v-else
              class="active-chat"
            >
              <!-- Chat Navbar -->
              <div
                v-if="!noHeader"
                class="chat-navbar"
              >
                <header class="chat-header">

                  <!-- Avatar & Name -->
                  <div class="d-flex align-items-center">

                    <!-- Toggle Icon -->
                    <div class="sidebar-toggle d-block d-lg-none mr-1">
                      <feather-icon
                        icon="MenuIcon"
                        class="cursor-pointer"
                        size="21"
                        @click="mqShallShowLeftSidebar = true"
                      />
                    </div>

                    <b-avatar
                      size="36"
                      :src="selectedChat.contact ? selectedChat.contact.avatar : null"
                      class="mr-1 cursor-pointer badge-minimal"
                    />
                    <h6 class="mb-0">
                      {{ (selectedChat.contact ? selectedChat.contact.name : selectedChat.name) || 'Администратор' }}
                    </h6>
                  </div>

                  <!-- Contact Actions -->
                  <div class="d-flex align-items-center">
                    <b-button
                      v-if="selectedChat.lead_reply"
                      variant="info"
                      class="mr-50"
                      :to="{name: 'cabinet.admin.lead', params: {lead_id: selectedChat.lead_reply.lead_id}}"
                    >
                      <feather-icon
                        icon="ExternalLinkIcon"
                        class="mr-sm-50"
                      />
                      <span class="d-none d-sm-inline-block">Заявка №{{ selectedChat.lead_reply.lead_id }}</span>
                    </b-button>
                    <b-button
                      v-if="isAdmin"
                      class="btn-icon mr-50"
                      variant="primary"
                      :disabled="roomCreating"
                      @click="createRoom"
                    >
                      <b-spinner
                        v-if="roomCreating"
                        type="grow"
                        small
                      />
                      <feather-icon
                        v-else
                        icon="VideoIcon"
                      />
                    </b-button>
                    <b-button
                      variant="flat-secondary"
                      @click="closeChat"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-sm-50"
                      />
                      <span class="d-none d-sm-inline-block">Закрыть</span>
                    </b-button>
                  </div>
                </header>
              </div>

              <!-- User Chat Area -->
              <div
                ref="refChatLogPS"
                class="user-chats scroll-area overflow-auto"
                :class="{rounded: centered}"
                :style="fullHeight ? 'height: calc(100vh - 145px)' : ''"
              >
                <chat-log
                  v-if="selectedChat"
                  ref="chatMessages"
                  :key="selectedChat.id"
                  :chat="selectedChat"
                  :sender="user ? user : {}"
                  @scrollToBottom="scrollToBottomInChatLog"
                />
              </div>

              <!-- Message Input -->
              <b-form
                class="chat-app-form"
                @submit.prevent="sendMessage"
              >
                <b-input-group class="input-group-merge form-send-message mr-1">
                  <b-form-input
                    ref="chatInput"
                    v-model="chatInputMessage"
                    autofocus
                    type="text"
                    autocomplete="off"
                    placeholder="Написать сообщение..."
                  />
                </b-input-group>
                <b-button
                  variant="primary"
                  type="submit"
                >
                  Отправить
                </b-button>
              </b-form>
            </div>
          </section>

          <!-- Active Chat Contact Details Sidebar -->
          <chat-active-chat-content-details-sidedbar
            v-if="!noHeader"
            :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
            :contact="selectedChat || {}"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar, BForm, BInputGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { v4 } from 'uuid'
import ChatActiveChatContentDetailsSidedbar from '@/components/chat/ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from '@/components/chat/ChatLog.vue'
import { adminRole, userRoles } from '../../store/user'

export default {
  name: 'ChatArea',
  components: {
    // BSV
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BSpinner,

    // SFC
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  props: {
    selectedChat: {
      type: Object,
      default: null,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingChats: false,
    sedingMessage: false,
    roomCreating: false,
    chats: [],
    mqShallShowLeftSidebar: false,
    chatInputMessage: '',
    shallShowActiveChatContactSidebar: false,
    shallShowUserProfileSidebar: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isAdmin() {
      return this.userRole === adminRole
    },
  },
  watch: {
    selectedChat() {
      this.chatInputMessage = ''
      this.$nextTick(() => {
        if (this.$refs.chatInput) {
          this.$refs.chatInput.focus()
        }
      })
    },
  },
  methods: {
    scrollToBottomInChatLog() {
      const scrollEl = this.$refs.refChatLogPS
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    closeChat() {
      this.$emit('chatClosed')
    },
    async sendMessage() {
      this.chatInputMessage = this.chatInputMessage.trim()
      if (!this.chatInputMessage) return

      this.sedingMessage = true
      const text = this.chatInputMessage
      const chatId = this.selectedChat.id
      const tempId = Date.now()
      const newMessage = {
        id: tempId,
        text,
        sender: {
          id: this.user.id,
          avatar: this.user.avatar,
        },
      }
      this.$refs.chatMessages.addMessage(newMessage)
      this.chatInputMessage = ''
      const savedMessage = await this.$chatsHttp.post(`/chats/${chatId}/messages`, { text })
      this.$refs.chatMessages.updateMessage(tempId, savedMessage)
      this.sedingMessage = false
    },
    async createRoom() {
      this.roomCreating = true

      const chatId = this.selectedChat.id
      const roomId = v4()
      const roomMessage = await this.$chatsHttp.post(`/chats/${chatId}/messages/room`, { room_id: roomId })
      this.$refs.chatMessages.addMessage(roomMessage)

      this.roomCreating = false
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
