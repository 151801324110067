import { render, staticRenderFns } from "./ChatArea.vue?vue&type=template&id=b24bfb1c&scoped=true&"
import script from "./ChatArea.vue?vue&type=script&lang=js&"
export * from "./ChatArea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b24bfb1c",
  null
  
)

export default component.exports